import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/starswap-front-home/starswap-front-home/src/layouts/blogPost.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Just over a year ago, we raised a seed round from Paradigm in order to advance research and development of the Starswap protocol and other decentralized products on Ethereum.`}</p>
    <p>{`The Starswap protocol is now one of the most widely-used platforms on Ethereum, with approximately `}{`$`}{`1.5B in volume in July 2020 alone. While this indicates initial protocol-market fit, we are even more excited for what comes next.`}</p>
    <p>{`Today, we’re thrilled to announce that we have raised `}{`$`}{`11M in Series A funding led by Andreessen Horowitz with additional investments from USV, Paradigm, Version One, Variant, Parafi Capital, SV Angel, and A.Capital.`}</p>
    <p>{`This investment round will help us grow our team to build Starswap V3, which will dramatically increase the flexibility and capital efficiency of the protocol.`}</p>
    <p>{`We can’t wait to present our design to the Ethereum community in the coming months.`}</p>
    <h1 {...{
      "id": "we-are-hiring",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#we-are-hiring",
        "aria-label": "we are hiring permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`We are hiring!`}</h1>
    <p>{`Job postings for engineers and a community manager are already up, and more will be posted over the coming months.`}</p>
    <p><a parentName="p" {...{
        "href": "https://starswap.xyz/about#jobs"
      }}>{`https://starswap.xyz/about#jobs`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      